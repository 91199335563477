@use '../../assets/styles/vars.scss' as vars;

.root {
  background-image: url('/assets/images/back_1600.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100%;
  padding: var(--mantine-spacing-lg);

  .footer {
    & .text {
      text-align: center;

      p {
        color: var(--mantine-color-kt-blue-7);
      }

      a {
        color: var(--mantine-color-yellow-9);
      }

      p,
      a {
        font-weight: 600;
      }
    }
  }
}
